<template>
  <v-row class="pa-4">
    <v-col cols="12">
      <h1 class="primary--text text-center mb-4">Financements engagés (en k€)</h1>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr class="primary">
              <th class="white--text font-weight-bold">Année d'arrêté</th>
              <th class="white--text font-weight-bold">Travaux et MOE engagés</th>
              <th class="white--text font-weight-bold">Travaux et MOE payés</th>

              <th class="white--text font-weight-bold">Total financements demandés</th>
              <th class="white--text font-weight-bold">Total financements payés</th>
              <th class="white--text font-weight-bold">Reste à payer déjà financé</th>

            </tr>
          </thead>
          <tbody v-if="loading === false">
            <tr @click="year = data.year" v-for="data in financement_engages" :key="data.year">
              <td>{{data.year}}</td>
              <td>{{arrondirKeuros(data.travaux_moe_engagees)}}</td>
              <td>{{ arrondirKeuros(data.travaux_moe_payees) }}</td>
              <td>{{arrondirKeuros(data.total_financements_arretes)}}</td>
              <td>{{arrondirKeuros(data.total_financements_payes)}}</td>
              <td>{{ arrondirKeuros(data.reste_a_payer_finance) }}</td>

            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-simple-table v-if="year !== null">
        <template v-slot:default>
          <thead>
            <tr class="primary">
              <th class="white--text font-weight-bold">Bénéficiaire</th>
              <th class="white--text font-weight-bold">Travaux et MOE engagés</th>
              <th class="white--text font-weight-bold">Travaux et MOE payés</th>

              <th class="white--text font-weight-bold">Total financements demandés</th>
              <th class="white--text font-weight-bold">Total financements payés</th>
              <th class="white--text font-weight-bold">Reste à payer déjà financé</th>
            </tr>
          </thead>
          <tbody v-if="loading === false">
            <tr v-for="data in detailsForYear" :key="data.year">
              <td>{{data.fullname}}</td>
              <td>{{arrondirKeuros(data.travaux_moe_engagees)}}</td>
              <td>{{arrondirKeuros(data.travaux_moe_payees)}}</td>
              <td>{{arrondirKeuros(data.total_financements_arretes)}}</td>
              <td>{{arrondirKeuros(data.total_financements_payes)}}</td>
              <td>{{ arrondirKeuros(data.reste_a_payer_finance) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-progress-circular v-if="loading" color="primary" indeterminate class="center-block"/>
    </v-col>
  </v-row>
</template>
<script>
import {arrondirKeuros} from "@/util";

export default {
  name: 'FinancementsEngages',
  async mounted() {
    this.financement_engages = await this.$store.dispatch("stats/fetchFinancementsEngages");
    this.loading = false;
  },

  data() {
    return {
      loading: false,
      financement_engages: [],
      year: null,
    }
  },
  methods: {
    formatNumbers(value) {
      return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(
        value,
      );
    },
    arrondirKeuros,
  },
  computed: {
    detailsForYear() {
      if(this.year === null) return [];
      return this.financement_engages.find(data => data.year === this.year).details;
    }
  }
}
</script>